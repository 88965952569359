import React, { Component } from 'react'
import Banner from '../../Content/images/KetoCalculator/banner.jpg';
import Helmet from 'react-helmet';
import Inputs from './Inputs';
import Logo from '../../Content/images/logos/white.png';
import Outputs from './Outputs';
let shareUrl = '/';
export default class CalculatorPage extends Component {
    componentDidMount() {
        window.scrollTo(0,0);
    }
    render() {
        return (
            <div>
                <Helmet>
                    <meta name="title" content="Keto Calculator | New Image Keto | Alpha Lipid SDII Programme"/>
                    <meta name="description" content="A free keto calculator for the ketogenic diet to work out your macros to manage weight loss or muscle gain.  The best way to start a Keto plan."/>
                    <meta name="keywords" content="Free, Keto, Calculator, Ketogenic Diet, Macros, Weight Loss, Weight Management, Starting Keto, Plan, Programme, Alpha Lipid™" />
                    <meta property="og:image:width" content="1200" />
                    <meta property="og:image:height" content="630" />
                    <meta property="og:url" content={shareUrl} />
                    <meta property="og:title" content="Keto Calculator | New Image Keto | Alpha Lipid SDII Programme"/>
                    <meta name="twitter:title" content="Keto Calculator | New Image Keto | Alpha Lipid SDII Programme"/>
                    <meta property="og:description" content="A free keto calculator for the ketogenic diet to work out your macros to manage weight loss or muscle gain.  The best way to start a Keto plan." />
                    <meta property="twitter:description" content="A free keto calculator for the ketogenic diet to work out your macros to manage weight loss or muscle gain.  The best way to start a Keto plan." />
                    <meta property="og:image" content={Banner} />
                    <meta property="og:image:url" content={Banner}/>
                    <meta name="twitter:image" content={Banner}/>
                    <meta name="twitter:card" content={Banner}/>
                </Helmet> 
                <div className="bg-primary pt-10 text-center">
                    <div className="max-w-md mx-auto">
                        
                        <img src={Logo} alt="New_Image™ Keto" className="block w-64 mx-auto"/>
                        <h1 className="block text-3xl font-bold text-white">THE KETO CALCULATOR</h1>
                    </div>
                </div>
                <div className="relative py-2">
                    <img src={Banner} alt="New_Image™ Keto Calculator" style={{
                        width: '300px',
                        border: '15px solid #fff',
                        height: '300px',
                        borderRadius: '50%',
                        display: 'block',
                        position: 'relative',
                        zIndex: '50',
                        margin: '0 auto',
                    }}/>
                    <div className="bg-primary absolute w-full top-0" style={{height: '50%'}}></div>
                    <div className="absolute w-full bottom-0" style={{background: '#f1f1f1', height: '50%'}}></div>
                </div>
                <div style={{background: '#f1f1f1'}} className="py-10"> 
                    <Inputs calculator={this.props.calculator} user={this.props.user} uid={this.props.uid}/>
                    <Outputs calculator={this.props.calculator} uid={this.props.uid}/>
                </div>
            </div>
        )
    }
}
